import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Img from "gatsby-image"
import coffeeMaker from '../images/coffeeMaker.png'
import frenchPress from '../images/frenchPress.png'
import chemex from '../images/chemex.png'
import mokaPot from '../images/mokaPot.png'
import espressoMachine from '../images/espressoMachine.png'
import refillablePods from '../images/refillablePods.png'
import {graphql, Link, useStaticQuery} from "gatsby";
import {slugify} from "../helpers";
import Img from "gatsby-image";

const ZgjidhniKafeneTuaj = (props) => {
const [experience, setExperience] = useState()
const [device, setDevice] = useState()

    const tree = {
        Fillestar: {
            coffeeMaker: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            frenchPress: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            chemex: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            mokaPot: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            espressoMachine: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            refillablePods: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
        },
        Mesatar: {
            coffeeMaker: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            frenchPress: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            chemex: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            mokaPot: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            espressoMachine: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            refillablePods: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
        },

        Barista: {
            coffeeMaker: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            frenchPress: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            chemex: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            mokaPot: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            espressoMachine: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
            refillablePods: {
                coffee1: 'Coffee 1',
                coffee2: 'Coffee 2',
                coffee3: 'Coffee 3',
            },
        }
    }
    const data = useStaticQuery(graphql`
    query UserSelectContentfulProducts {
      allContentfulProduct {
        edges {
          node {
            id
            name
            priceInCents
            photos {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
    console.log(data.allContentfulProduct.edges)
    return (
        <Layout>
            <SEO title="Blog"/>
            <div className="container mx-auto flex flex-col items-center">
                <div>
                    <p className={'text-3xl mb-8'}>Zgjidhni kafen tuaj</p>
                </div>
                <div>
                    <p className={'p-6 w-fulltext-xl font-bold rounded-lg mb-8'}>Sa eksperience keni me kafen</p>
                </div>
                <div className={'flex flex-col md:flex-row mb-2'}>
                    <p onClick={e => setExperience('Fillestar')} className={`cursor-pointer p-6 w-64 border rounded-lg m-6 ${experience === 'Fillestar' ? ' border-brand-700 text-brand-700': 'text-gray-600 border-gray-600'}`}>Fillestar</p>
                    <p onClick={e => setExperience('Mesatar')} className={`cursor-pointer p-6 w-64 border rounded-lg m-6 ${experience === 'Mesatar' ? ' border-brand-700 text-brand-700': 'text-gray-600 border-gray-600'}`}>Mesatar</p>
                    <p onClick={e => setExperience('Barista')} className={`cursor-pointer p-6 w-64 border rounded-lg m-6 ${experience === 'Barista' ? ' border-brand-700 text-brand-700': 'text-gray-600 border-gray-600'}`}>Barista</p>
                    <p onClick={e => setExperience('All')} className={`cursor-pointer p-6 w-64 border rounded-lg m-6 ${experience === 'All' ? ' border-brand-700 text-brand-700': 'text-gray-600 border-gray-600'}`}>Nuk jam i sigurte</p>
                </div>
                {experience &&  <div>
                    <p className={'p-6 w-full text-xl font-bold rounded-lg mb-8'}>Cfare pajisje keni ose deshironi te bleni ne te ardhmen</p>
                </div>}
                {experience && <div className={'w-full flex flex-wrap align-center items-center mb-16 max-w-3xl'}>

                    <div onClick={e => setDevice('coffeeMaker')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'coffeeMaker' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={coffeeMaker} alt=""/>
                    </div>
                    <div onClick={e => setDevice('frenchPress')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'frenchPress' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={frenchPress} alt=""/>
                    </div>
                    <div onClick={e => setDevice('chemex')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'chemex' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={chemex} alt=""/>
                    </div>
                    <div onClick={e => setDevice('mokaPot')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'mokaPot' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={mokaPot} alt=""/>
                    </div>
                    <div onClick={e => setDevice('espressoMachine')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'espressoMachine' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={espressoMachine} alt=""/>
                    </div>
                    <div onClick={e => setDevice('refillablePods')} className={`cursor-pointer w-full md:w-64 text-center p-1 ${device === 'refillablePods' ? ' border rounded-lg  border-brand-800 ': ''}`}>
                        <img src={refillablePods} alt=""/>
                    </div>
                </div>}

                {device && <div>
                    <div className={'text-center'}>
                        <p className={'text-3xl mb-8'}>Kafete qe ju pershtaten me shume per ju jane:</p>
                    </div>
                    <div className="flex flex-wrap items-center w-full">
                        {data.allContentfulProduct.edges.filter(({node: product}) => {
                            return product.id !== props.exclude
                        }).map(({node: product}) => (
                            <div className={"w-full md:w-1/6 p-2"} key={product.id}>
                                <Link to={'/product/' + slugify(product.name + " " + product.id)}>
                                    {product.photos && <Img fluid={product.photos[0].localFile.childImageSharp.fluid}/>}
                                    <p className={`h-10 ${props.size === 'sm' ? "text-sm" : "text-base "}`} key={product.id}>{product.name}</p>
                                    <p className="font-bold text-sm">{(product.priceInCents / 100).toFixed(0)}Lek</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>}

            </div>
        </Layout>

    )
}
export default ZgjidhniKafeneTuaj
